:root {
  --red: #C9082A;
  --green-white: #E2E7DD;
  --wheat: #CE9E63;
  --purple: #7D7582;
  --green-black: #1C2624;
  --white: white;
  
  --copy-f: 'DM Sans', Arial, Helvetica, sans-serif;
  --heading-f: 'Inter', sans-serif;
}

*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
  font-family: var(--copy-f);
  color: var(--green-black);
}

a {
  display: inline-block;
  text-decoration: none;
  color: var(--green-black);
  transition: all 200ms ease-in-out;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}